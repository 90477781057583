import { Stack, Typography } from "@mui/material";
import React from "react";

function MobileSection4() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      paddingTop={"50px"}
      paddingBottom={"50px"}
      sx={{ backgroundColor: "#D4E9FA" }}
    >
      <Typography
        fontSize={26}
        color={"#1C7BD2"}
        fontWeight={700}
        textAlign={"center"}
        lineHeight={"130%"}
      >
        마케팅을 위한 초기설정
        <br />
        SNS허브가 도와드릴게요
      </Typography>

      <Typography
        fontSize={20}
        color={"#1F1F1F"}
        fontWeight={400}
        textAlign={"center"}
        fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
        marginTop={"27px"}
        lineHeight={"130%"}
      >
        계정관리부터 노출까지
        <br />
        혼자 할 수 있도록
      </Typography>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginTop={"27px"}
        sx={{
          padding: "20px 25px 25px 25px",
          borderRadius: "20px",
          backgroundColor: "#F0F6FA",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
          backdropFilter: "blur(7.5px)",
          minWidth: "280px",
        }}
      >
        <img
          alt="scrion4_content1"
          src={`${process.env.PUBLIC_URL}/assets/images/mobile/scrion4_content1.png`}
          width={"158px"}
          height={"130px"}
        />

        <Typography
          fontSize={20}
          color={"#3CA4EE"}
          fontWeight={700}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"120%"}
        >
          빅데이터 분석
        </Typography>

        <Typography
          fontSize={14}
          color={"#000"}
          fontWeight={300}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"140%"}
        >
          빅데이터를 기반으로 시장의 흐름을 <br />
          파악하여 체계적이고 세분화된 <br />
          마케팅을 기획합니다
          <br />
        </Typography>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginTop={"27px"}
        sx={{
          padding: "20px 25px 25px 25px",
          borderRadius: "20px",
          backgroundColor: "#F0F6FA",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
          backdropFilter: "blur(7.5px)",
          minWidth: "280px",
        }}
      >
        <img
          alt="scrion4_content2"
          src={`${process.env.PUBLIC_URL}/assets/images/mobile/scrion4_content2.png`}
          width={"130px"}
          height={"130px"}
        />

        <Typography
          fontSize={20}
          color={"#3CA4EE"}
          fontWeight={700}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"120%"}
        >
          콘텐츠 컨설팅
        </Typography>

        <Typography
          fontSize={14}
          color={"#000"}
          fontWeight={300}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"140%"}
        >
          업종별로 실시간 반응이 좋은
          <br />
          콘텐츠를 분석하여 시장상황에
          <br />
          맞는 컨설팅을 해드립니다
        </Typography>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginTop={"27px"}
        sx={{
          padding: "20px 25px 25px 25px",
          borderRadius: "20px",
          backgroundColor: "#F0F6FA",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
          backdropFilter: "blur(7.5px)",
          minWidth: "280px",
        }}
      >
        <img
          alt="scrion4_content3"
          src={`${process.env.PUBLIC_URL}/assets/images/mobile/scrion4_content3.png`}
          width={"126px"}
          height={"126px"}
        />

        <Typography
          fontSize={20}
          color={"#3CA4EE"}
          fontWeight={700}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"120%"}
        >
          안전한 서비스
        </Typography>

        <Typography
          fontSize={14}
          color={"#000000"}
          fontWeight={300}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"140%"}
        >
          150만명의 이용하는 서비스로
          <br />
          각 플랫폼의 가이드라인을 준수하며,
          <br />
          안전한 서비스를 제공합니다
        </Typography>
      </Stack>
    </Stack>
  );
}

export default MobileSection4;
