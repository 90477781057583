import React from "react";
import MobileHome from "./components/mobile/MobileHome";
import PcHome from "./components/pc/PcHome";
import { Box } from "@mui/material";
import MobileReHomeHome from "./components/mobile-review/MobileReviewHome";
import PcReviewHome from "./components/pc-review/PcReviewHome";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MobileCoupon from "./components/mobile-review/MobileCoupon";

export default function App() {
  function getContent() {
    if (false) {
      return (
        <React.Fragment>
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
            }}
          >
            <MobileReHomeHome />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            <PcReviewHome />
          </Box>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "block",
                          lg: "none",
                        },
                      }}
                    >
                      <MobileHome />
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "none",
                          lg: "block",
                        },
                      }}
                    >
                      <PcHome />
                    </Box>
                  </>
                }
              />
              <Route path="/coupon" element={<MobileCoupon />} />
              <Route
                path="*"
                element={
                  <>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "block",
                          lg: "none",
                        },
                      }}
                    >
                      <MobileHome />
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "none",
                          lg: "block",
                        },
                      }}
                    >
                      <PcHome />
                    </Box>
                  </>
                }
              ></Route>
            </Routes>
          </BrowserRouter>
          {/* <MobileHome /> */}
        </React.Fragment>
      );
    }
  }

  return getContent();
}
