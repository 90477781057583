import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function PcSection6() {
  return (
    <Box
      sx={{
        background: "#FFF",
      }}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          paddingTop={"140px"}
          paddingBottom={"140px"}
          // sx={{ width: "100%" }}
        >
          <img
            alt="section6_title"
            src={`${process.env.PUBLIC_URL}/assets/images/pc/section6_title.png`}
            width={"359px"}
            height={"auto"}
            style={{ marginRight: "110px" }}
          />

          <Button
            variant="contained"
            sx={{
              minWidth: "420px",
              borderRadius: "10px",
              backgroundColor: "#000",
              color: "#fff",
              fontFamily: `"SUIT", "Helvetica", "Arial", sans-serif`,
              fontSize: "28px",
              fontWeight: 700,
              lineHeight: "120%",
              py: "15px",
              "&:hover": {
                backgroundColor: "#4a4a4a",
              },
              color: "white",
              textDecoration: "none",
            }}
            component="a"
            href="http://pf.kakao.com/_QxkrBG"
            target="_blank"
          >
            <img
              alt="section6_download"
              src={`${process.env.PUBLIC_URL}/assets/images/pc/section6_download.png`}
              width={"40px"}
              height={"auto"}
              style={{ marginRight: "15px" }}
            />
            쿠폰받고 마케팅 시작하기
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}

export default PcSection6;
