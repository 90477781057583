import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";

function PcSection1() {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(90deg, #ECF4FA 0.47%, #FFF 44.76%, #ECF4FA 100%)",
      }}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingTop={"185px"}
          paddingBottom={"145px"}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            paddingBottom={"40px"}
            paddingRight={"60px"}
          >
            <img
              alt="section1_logo"
              src={`${process.env.PUBLIC_URL}/assets/images/pc/section1_logo.png`}
              width={"127px"}
              height={"auto"}
            />

            <Typography
              fontSize={50}
              color={"#56A4FF"}
              fontWeight={700}
              fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
              marginTop={"18px"}
              lineHeight={"120%"}
            >
              마케팅 무료로 시작해봐!
            </Typography>

            <Box marginTop={"20px"}>
              <Typography
                fontSize={24}
                color={"#1F1F1F"}
                fontWeight={700}
                fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
                lineHeight={"160%"}
                component={"span"}
              >
                브랜드 성장을&nbsp;
              </Typography>
              <Typography
                fontSize={24}
                color={"#1F1F1F"}
                fontWeight={400}
                fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
                lineHeight={"160%"}
                component={"span"}
              >
                위한&nbsp;
              </Typography>
              <Typography
                fontSize={24}
                color={"#1F1F1F"}
                fontWeight={700}
                fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
                lineHeight={"160%"}
                component={"span"}
              >
                24시간 마케팅 솔루션
                <br />
              </Typography>
              <Typography
                fontSize={24}
                color={"#1F1F1F"}
                fontWeight={400}
                fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
                lineHeight={"160%"}
                component={"span"}
              >
                대한민국 소상공인부터 개인 인플루언서에게
                <br />
              </Typography>
              <Typography
                fontSize={24}
                color={"#1F1F1F"}
                fontWeight={400}
                fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
                lineHeight={"160%"}
                component={"span"}
              >
                국내 최저가로
              </Typography>
              <Typography
                fontSize={24}
                color={"#1F1F1F"}
                fontWeight={700}
                fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
                lineHeight={"160%"}
                component={"span"}
              >
                셀프마케팅 서비스
              </Typography>
              <Typography
                fontSize={24}
                color={"#1F1F1F"}
                fontWeight={400}
                fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
                lineHeight={"160%"}
                component={"span"}
              >
                를 제공합니다.
              </Typography>
            </Box>

            <Button
              variant="contained"
              sx={{
                fontSize: "28px",
                color: "#FFF",
                fontWeight: 500,
                fontFamily: `"Noto Sans KR", "Helvetica", "Arial", sans-serif`,
                borderRadius: "20px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
                backgroundColor: "#0062F5",
                width: "442px",
                height: "77px",
                marginTop: "150px",
                lineHeight: "100%",

                textDecoration: "none",
              }}
              component="a"
              href="http://pf.kakao.com/_QxkrBG"
              target="_blank"
            >
              무료쿠폰 다운받기
            </Button>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
            >
              <Typography
                fontSize={16}
                color={"#58A8E7"}
                fontWeight={400}
                fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
                marginTop={"20px"}
                textAlign={"center"}
                lineHeight={"120%"}
              >
                *본 이벤트는 소상공인과 인플루언서 한정으로 쿠폰이 지급됩니다
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            <img
              alt="saction1_image"
              src={`${process.env.PUBLIC_URL}/assets/images/pc/saction1_image.png`}
              width={"100%"}
              height={"auto"}
              style={{ maxWidth: "553px" }}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default PcSection1;
