import { Button, Stack, Typography } from "@mui/material";
import QueryString from "qs";
import React from "react";
import { useLocation, useParams } from "react-router";

function MobileCouponSection1() {
  const params = useParams();
  const location = useLocation(); //바뀐 부분
  const [coupon, setCoupon] = React.useState("");
  React.useEffect(() => {
    // console.log(`location`, location);
    try {
      const queryData = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      // console.log(queryData);
      if (queryData.serial) {
        setCoupon(queryData.serial);
      }
    } catch (error) {
      console.error("쿠폰 번호를 가져오는데 실패했습니다.");
      console.log(error);
    }
  }, [location]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      paddingTop={"60px"}
      paddingBottom={"60px"}
      sx={{ backgroundColor: "#FAFDFF" }}
    >
      <Typography
        fontSize={37}
        color={"#358BF0"}
        fontWeight={700}
        textAlign={"center"}
        lineHeight={"120%"}
        component={"h1"}
      >
        퍼포먼스 마케팅
        <br />
        무료로 시작해봐!
      </Typography>

      {!coupon && (
        <Typography
          fontSize={20}
          color={"#1F1F1F"}
          fontWeight={500}
          textAlign={"center"}
          marginTop={"26px"}
          lineHeight={"120%"}
          component={"h2"}
        >
          SNS셀프 마케팅 쿠폰
          <br />
          채널추가시 쿠폰 자동 발급!
        </Typography>
      )}

      {coupon && (
        <>
          {" "}
          <Typography
            fontSize={20}
            color={"#1F1F1F"}
            fontWeight={500}
            textAlign={"center"}
            marginTop={"26px"}
            lineHeight={"120%"}
            component={"h2"}
          >
            쿠폰 번호
          </Typography>
          <Typography
            fontSize={20}
            color={"#1F1F1F"}
            fontWeight={700}
            textAlign={"center"}
            lineHeight={"120%"}
            component={"h3"}
          >
            {coupon ?? ""}
          </Typography>
        </>
      )}

      <img
        alt="section1_coupon"
        src={`${process.env.PUBLIC_URL}/assets/images/mobile/section1_coupon.png`}
        width={"auto"}
        height={"220px"}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "55px",
        }}
      />

      <Typography
        fontSize={14}
        color={"#58A8E7"}
        fontWeight={300}
        textAlign={"center"}
        marginTop={"31px"}
        fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
        lineHeight={"120%"}
      >
        *본 이벤트는 소상공인과 인플루언서
        <br />
        한정으로 쿠폰이 지급됩니다
      </Typography>

      <Button
        // width="252px"
        // height="74px"
        variant="contained"
        sx={{
          marginTop: "41px",
          fontSize: "24px",
          fontWeight: 500,
          height: "73px",
          px: "30px",
          backgroundColor: "#0062F5",
          borderRadius: "20px",
          color: "white",
          textDecoration: "none",
        }}
        component="a"
        // href="http://pf.kakao.com/_QxkrBG"
        href="https://www.snshub.co.kr/"
        target="_blank"
      >
        쿠폰등록하러 가기
      </Button>

      {/* <Button
        variant="contained"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          marginTop: "28px",
          marginLeft: "35px",
          marginRight: "35px",
          maxWidth: "320px",
          height: "54px",
          fontFamily: `"SUIT", "Helvetica", "Arial", sans-serif`,
          fontWeight: "700",
          fontSize: "18px",
          borderRadius: "10px",
          color: "white",
          textDecoration: "none",
        }}
        component="a"
        href="http://pf.kakao.com/_QxkrBG"
        target="_blank"
        fullWidth
      >
        무료쿠폰 사용방법 문의하기
        <img
          alt="section6_title"
          src={`${process.env.PUBLIC_URL}/assets/images/mobile/section6_arrow.png`}
          width={"12.46px"}
          height={"auto"}
          style={{ marginLeft: "12px" }}
        />
      </Button> */}

      {/* <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"28.5px"}
      >
        <img
          alt="section1_notice_icon"
          src={`${process.env.PUBLIC_URL}/assets/images/mobile/section1_notice_icon.png`}
          width={"22px"}
          height={"22px"}
        />

        <Typography
          fontSize={"14px"}
          color={"rgba(255, 65, 65, 0.30)"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
        >
          이 광고를 벗어나면 받을 수 없어요
        </Typography>
      </Stack> */}
    </Stack>
  );
}

export default MobileCouponSection1;
