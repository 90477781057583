import { Stack, Typography } from "@mui/material";
import React from "react";

function MobileFooter() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      paddingTop={"21px"}
      paddingBottom={"21px"}
      sx={{ backgroundColor: "#2C2C2C" }}
    >
      <Typography
        fontSize={"16px"}
        fontWeight={500}
        fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
        color={"#AEAEAE"}
        textAlign={"center"}
      >
        (주)크리에이티브소프트 대표 : 김관희 <br />
        사업자 등록번호 355-88-00591
        <br />
        통신판매 제 2019-서울동작-1156호
        <br />
        서울시 동작구 상도로 396 2층
        <br />
        대표이메일 cs.snshub@gmail.com
        <br />
        대표번호 : 070-4258-1379
      </Typography>
    </Stack>
  );
}

export default MobileFooter;
