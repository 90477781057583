import { Stack, Typography } from "@mui/material";
import React from "react";

function MobileSection5() {
  return (
    <Stack
      sx={{ backgroundColor: "#F9F9F9" }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={"30px"}
    >
      <Typography
        fontSize={26}
        color={"#4090D9"}
        fontWeight={700}
        textAlign={"center"}
        lineHeight={"130%"}
        letterSpacing={"-0.78px"}
      >
        다양한 플랫폼에서
        <br />
        SNS허브를 이용할 수 있어요!
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2.5}
        marginTop={"43px"}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F0F0F0"
          borderRadius={"20px"}
          padding={"10px"}
          minHeight={"100px"}
          minWidth={"100px"}
        >
          <img
            alt="section5_instagram"
            src={`${process.env.PUBLIC_URL}/assets/images/mobile/section5_instagram.png`}
            width={"60px"}
            height={"60px"}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />

          <Typography
            fontSize={14}
            color={"#4E4E4E"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"5px"}
            lineHeight={"130%"}
          >
            인스타그램
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F0F0F0"
          borderRadius={"20px"}
          padding={"10px"}
          minHeight={"100px"}
          minWidth={"100px"}
        >
          <img
            alt="section5_youtube"
            src={`${process.env.PUBLIC_URL}/assets/images/mobile/section5_youtube.png`}
            width={"60px"}
            height={"60px"}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />

          <Typography
            fontSize={14}
            color={"#4E4E4E"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"5px"}
            lineHeight={"130%"}
          >
            유튜브
          </Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F0F0F0"
          borderRadius={"20px"}
          padding={"10px"}
          minHeight={"100px"}
          minWidth={"100px"}
        >
          <img
            alt="section5_tikto"
            src={`${process.env.PUBLIC_URL}/assets/images/mobile/section5_tikto.png`}
            width={"60px"}
            height={"60px"}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />

          <Typography
            fontSize={14}
            color={"#4E4E4E"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"5px"}
            lineHeight={"130%"}
          >
            틱톡
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2.5}
        marginTop={"20px"}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F0F0F0"
          borderRadius={"20px"}
          padding={"10px"}
          minHeight={"100px"}
          minWidth={"100px"}
        >
          <img
            alt="section5_facebook"
            src={`${process.env.PUBLIC_URL}/assets/images/mobile/section5_facebook.png`}
            width={"60px"}
            height={"60px"}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />

          <Typography
            fontSize={14}
            color={"#4E4E4E"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"5px"}
            lineHeight={"130%"}
          >
            페이스북
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F0F0F0"
          borderRadius={"20px"}
          padding={"10px"}
          minHeight={"100px"}
          minWidth={"100px"}
        >
          <img
            alt="section5_telegram"
            src={`${process.env.PUBLIC_URL}/assets/images/mobile/section5_telegram.png`}
            width={"60px"}
            height={"60px"}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />

          <Typography
            fontSize={14}
            color={"#4E4E4E"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"5px"}
            lineHeight={"130%"}
          >
            텔레그램
          </Typography>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F0F0F0"
          borderRadius={"20px"}
          padding={"10px"}
          minHeight={"100px"}
          minWidth={"100px"}
        >
          <img
            alt="section5_twitter"
            src={`${process.env.PUBLIC_URL}/assets/images/mobile/section5_twitter.png`}
            width={"60px"}
            height={"60px"}
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />

          <Typography
            fontSize={14}
            color={"#4E4E4E"}
            fontWeight={500}
            textAlign={"center"}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"5px"}
            lineHeight={"130%"}
          >
            트위터
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default MobileSection5;
