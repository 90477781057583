import { AppBar, Box, Toolbar } from "@mui/material";
import React from "react";
import MobileSection1 from "./MobileSection1";
import MobileSection2 from "./MobileSection2";
import MobileSection3 from "./MobileSection3";
import MobileSection4 from "./MobileSection4";
import MobileSection5 from "./MobileSection5";
import MobileSection6 from "./MobileSection6";
import MobileFooter from "./MobileFooter";
import MobileCouponSection1 from "./MobileCouponSection1";

function MobileCoupon() {
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{
            background: "#FAFDFF",
            borderBottom: "0.5px solid rgba(0, 0, 0, 0.10)",
          }}
          elevation={0}
        >
          <Toolbar>
            <img
              alt="main_title"
              src={`${process.env.PUBLIC_URL}/assets/images/mobile/top_logo.png`}
              width={"auto"}
              height={"30px"}
              style={{ marginRight: "auto", marginLeft: "auto" }}
            />
          </Toolbar>
        </AppBar>
      </Box>

      <MobileCouponSection1 />
      {/* <MobileSection2 />
      <MobileSection3 />
      <MobileSection4 />
      <MobileSection5 />
      <MobileSection6 /> */}

      {/* <MobileFooter /> */}
    </React.Fragment>
  );
}

export default MobileCoupon;
