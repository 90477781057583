import { Button, Stack, Typography } from "@mui/material";
import React from "react";

function MobileSection1() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      paddingTop={"60px"}
      paddingBottom={"86px"}
      sx={{ backgroundColor: "#FAFDFF" }}
    >
      <Typography
        fontSize={37}
        color={"#358BF0"}
        fontWeight={700}
        textAlign={"center"}
        lineHeight={"120%"}
        component={"h1"}
      >
        SNS마케팅
        <br />
        무료로 시작해봐!
      </Typography>

      <Typography
        fontSize={20}
        color={"#1F1F1F"}
        fontWeight={500}
        textAlign={"center"}
        marginTop={"26px"}
        lineHeight={"120%"}
        component={"h2"}
      >
        SNS셀프 마케팅 쿠폰
        <br />
        채널추가시 쿠폰 자동 발급!
      </Typography>

      <img
        alt="section1_coupon"
        src={`${process.env.PUBLIC_URL}/assets/images/mobile/section1_coupon.png`}
        width={"auto"}
        height={"220px"}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "55px",
        }}
      />

      <Typography
        fontSize={14}
        color={"#B3B3B3"}
        fontWeight={300}
        textAlign={"center"}
        marginTop={"36px"}
        fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
        lineHeight={"120%"}
      >
        *본 이벤트는 소상공인과 인플루언서
        <br />
        한정으로 쿠폰이 지급됩니다
      </Typography>

      <Button
        // width="252px"
        // height="74px"
        variant="contained"
        sx={{
          marginTop: "34px",
          fontSize: "24px",
          fontWeight: 500,
          height: "73px",
          px: "30px",
          backgroundColor: "#0062F5",
          borderRadius: "20px",
          color: "white",
          textDecoration: "none",
        }}
        component="a"
        href="http://pf.kakao.com/_QxkrBG"
        target="_blank"
      >
        무료쿠폰 다운받기
      </Button>

      {/* <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"28.5px"}
      >
        <img
          alt="section1_notice_icon"
          src={`${process.env.PUBLIC_URL}/assets/images/mobile/section1_notice_icon.png`}
          width={"22px"}
          height={"22px"}
        />

        <Typography
          fontSize={"14px"}
          color={"rgba(255, 65, 65, 0.30)"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
        >
          이 광고를 벗어나면 받을 수 없어요
        </Typography>
      </Stack> */}
    </Stack>
  );
}

export default MobileSection1;
