import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";

function PcSection4() {
  return (
    <Box
      sx={{
        background: "#D4E9FA",
      }}
    >
      <Container>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingTop={"100px"}
          paddingBottom={"100px"}
        >
          <Typography
            fontSize={50}
            color={"#1C7BD2"}
            fontWeight={700}
            fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
            lineHeight={"130%"}
            textAlign={"center"}
          >
            마케팅을 위한 초기설정
            <br />
            SNS 허브가 도와드릴게요
          </Typography>

          <Typography
            fontSize={28}
            color={"#4D4D4D"}
            fontWeight={400}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"40px"}
            lineHeight={"130%"}
          >
            계정관리부터 노출까지 혼자 할 수 있도록
          </Typography>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={"60px"}
            sx={{
              fontFamily: `"Pretendard", "Helvetica", "Arial", sans-serif`,
              width: "100%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              paddingTop={"20px"}
              paddingBottom={"30px"}
              px={"25px"}
              backgroundColor="#F0F6FA"
              borderRadius={"20px"}
              sx={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                backdropFilter: "blur(7.5px)",
                minWidth: "290px",
              }}
            >
              <img
                alt="scrion4_content1"
                src={`${process.env.PUBLIC_URL}/assets/images/pc/scrion4_content1.png`}
                width={"auto"}
                height={"155px"}
              />

              <Typography
                fontSize={24}
                color={"#38A5F9"}
                fontWeight={600}
                textAlign={"center"}
                fontFamily="inherit"
                marginTop={"12px"}
              >
                빅데이터 분석
              </Typography>

              <Typography
                fontSize={18}
                color={"#000"}
                fontWeight={300}
                textAlign={"center"}
                fontFamily="inherit"
                marginTop={"12px"}
              >
                빅데이터를 기반으로 시장의 <br />
                흐름을 파악하여 체계적이고
                <br />
                세분화된 마케팅을 기획합니다
                <br />
              </Typography>
            </Stack>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              paddingTop={"20px"}
              paddingBottom={"30px"}
              px={"25px"}
              backgroundColor="#F0F6FA"
              borderRadius={"20px"}
              sx={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                backdropFilter: "blur(7.5px)",
                minWidth: "290px",
              }}
            >
              <img
                alt="scrion4_content2"
                src={`${process.env.PUBLIC_URL}/assets/images/pc/scrion4_content2.png`}
                width={"auto"}
                height={"155px"}
              />

              <Typography
                fontSize={24}
                color={"#38A5F9"}
                fontWeight={500}
                textAlign={"center"}
                fontFamily="inherit"
                marginTop={"12px"}
              >
                콘텐츠 컨설팅
              </Typography>

              <Typography
                fontSize={18}
                color={"#000"}
                fontWeight={300}
                textAlign={"center"}
                fontFamily="inherit"
                marginTop={"12px"}
              >
                업종별로 실시간 반응이 좋은
                <br />
                콘텐츠를 분석하여 시장상황에
                <br />
                맞는 컨설팅을 해드립니다
                <br />
              </Typography>
            </Stack>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              paddingTop={"20px"}
              paddingBottom={"30px"}
              px={"25px"}
              backgroundColor="#F0F6FA"
              borderRadius={"20px"}
              sx={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                backdropFilter: "blur(7.5px)",
                minWidth: "290px",
              }}
            >
              <img
                alt="scrion4_content3"
                src={`${process.env.PUBLIC_URL}/assets/images/pc/scrion4_content3.png`}
                width={"auto"}
                height={"155px"}
              />

              <Typography
                fontSize={24}
                color={"#38A5F9"}
                fontWeight={500}
                textAlign={"center"}
                fontFamily="inherit"
                marginTop={"12px"}
              >
                안전한 서비스
              </Typography>

              <Typography
                fontSize={18}
                color={"#000"}
                fontWeight={300}
                textAlign={"center"}
                fontFamily="inherit"
                marginTop={"12px"}
              >
                150만명의 이용하는 서비스로
                <br />
                각 플랫폼의 가이드라인을 준수하며,
                <br /> 안전한 서비스를 제공합니다
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default PcSection4;
