import { Stack, Typography } from "@mui/material";
import React from "react";

function MobileSection3() {
  return (
    <Stack
      sx={{ backgroundColor: "#DDEAF5" }}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        fontSize={26}
        color={"#1C7BD2"}
        fontWeight={700}
        textAlign={"center"}
        marginTop={"47px"}
        lineHeight={"130%"}
      >
        SNS 마케팅
        <br />
        이런 고민 해보셨나요?
      </Typography>

      <img
        alt="section1_notice_icon"
        src={`${process.env.PUBLIC_URL}/assets/images/mobile/section2_info.png`}
        width={"100%"}
        height={"auto"}
        style={{
          marginTop: "34px",
          marginRight: "22px",
          marginLeft: "18px",
          maxWidth: "350px",
        }}
      />
    </Stack>
  );
}

export default MobileSection3;
