import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function PcSection5() {
  return (
    <Box
      sx={{
        background: "#F9F9F9",
      }}
    >
      <Container>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingTop={"100px"}
          paddingBottom={"100px"}
        >
          <Typography
            fontSize={34}
            color={"#292929"}
            fontWeight={700}
            fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
            lineHeight={"150%"}
            letterSpacing={"-1.02px"}
            textAlign={"center"}
          >
            SNS허브를 이용하여
            <br />
            다양한 플랫폼의 마케팅을 진행 할수 있어요!
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={"21px"}
            marginTop={"40px"}
          >
            <Grid item>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100px"
                height="100px"
                backgroundColor="#F0F0F0"
                borderRadius={"50px"}
              >
                <img
                  alt="scrion5_instagram"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/scrion5_instagram.png`}
                  width={"80px"}
                  height={"auto"}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100px"
                height="100px"
                backgroundColor="#F0F0F0"
                borderRadius={"50px"}
              >
                <img
                  alt="section5_youtube"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/section5_youtube.png`}
                  width={"80px"}
                  height={"auto"}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100px"
                height="100px"
                backgroundColor="#F0F0F0"
                borderRadius={"50px"}
              >
                <img
                  alt="section5_tikto"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/section5_tikto.png`}
                  width={"80px"}
                  height={"auto"}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100px"
                height="100px"
                backgroundColor="#F0F0F0"
                borderRadius={"50px"}
              >
                <img
                  alt="section5_facebook"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/section5_facebook.png`}
                  width={"80px"}
                  height={"auto"}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100px"
                height="100px"
                backgroundColor="#F0F0F0"
                borderRadius={"50px"}
              >
                <img
                  alt="section5_twitter"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/section5_twitter.png`}
                  width={"80px"}
                  height={"auto"}
                />
              </Stack>
            </Grid>
            <Grid item>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100px"
                height="100px"
                backgroundColor="#F0F0F0"
                borderRadius={"50px"}
              >
                <img
                  alt="section5_telegram"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/section5_telegram.png`}
                  width={"80px"}
                  height={"auto"}
                />
              </Stack>
            </Grid>

            <Grid item>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width="100px"
                height="100px"
                backgroundColor="#F0F0F0"
                borderRadius={"50px"}
              >
                <img
                  alt="section5_pinterest"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/section5_pinterest.png`}
                  width={"80px"}
                  height={"auto"}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default PcSection5;
