import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import PcSection1 from "./PcSection1";
import PcSection2 from "./PcSection2";
import PcSection3 from "./PcSection3";
import PcSection4 from "./PcSection4";
import PcSection5 from "./PcSection5";
import PcSection6 from "./PcSection6";
import PcFooter from "./PcFooter";

function PcHome() {
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          sx={{
            background:
              "linear-gradient(90deg, #ECF4FA 0.47%, #FFF 44.76%, #ECF4FA 100%)",
            borderBottom: " 1px solid rgba(0, 0, 0, 0.10);",
          }}
        >
          <Container>
            <Toolbar disableGutters>
              <img
                alt="main_title"
                src={`${process.env.PUBLIC_URL}/assets/images/pc/top_logo.png`}
                width={"110px"}
                height={"auto"}
                // style={{ marginRight: "auto", marginLeft: "auto" }}
              />
              <Typography component="div" sx={{ flexGrow: 1 }}></Typography>
              <Button
                variant="contained"
                // color="inherit"
                sx={{
                  fontFamily: `"SUIT", "Helvetica", "Arial", sans-serif`,
                  // color: "#FEFFFF",
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "120%",
                  letterSpacing: "-0.36px",
                  boxShadow: "0",
                  backgroundColor: "#38A5F9",
                  // width: "176px",
                  // height: "40px",
                  borderRadius: "10px",
                  py: "10px",
                  "&:hover": {
                    backgroundColor: "#0090ff",
                  },
                  color: "white",
                  textDecoration: "none",
                }}
                component="a"
                href="http://pf.kakao.com/_QxkrBG"
                target="_blank"
              >
                <img
                  alt="top_button_icon"
                  src={`${process.env.PUBLIC_URL}/assets/images/pc/top_button_icon.png`}
                  width={"24px"}
                  height={"auto"}
                  style={{ marginRight: "10px" }}
                />
                셀프마케팅 문의
              </Button>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <PcSection1 />
      <PcSection2 />
      <PcSection3 />
      <PcSection4 />
      <PcSection5 />
      <PcSection6 />
      <PcFooter />
    </React.Fragment>
  );
}

export default PcHome;
