import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function PcFooter() {
  return (
    <Box
      sx={{
        background: "#2C2C2C",
      }}
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          paddingTop={"40px"}
          paddingBottom={"40px"}
          // sx={{ width: "100%" }}
        >
          <Typography
            fontSize={"16px"}
            fontWeight={400}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            color={"#9A9A9A"}
            textAlign={"center"}
            lineHeight={"160%"}
          >
            (주)크리에이티브소프트 | 대표이사 : 김관희 | 사업자 등록번호 :
            355-88-00591 | 통신판매 제 2019-서울동작-1156호 <br />
            주소 : 서울시 동작구 상도로 396 2층 | 대표이메일 :
            cs.snshub@gmail.com | 대표번호 : 070-4258-1379
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}

export default PcFooter;
