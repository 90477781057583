import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";

function PcSection2() {
  return (
    <Box
      sx={{
        background: "#DDEAF5",
      }}
    >
      <Container>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingTop={"100px"}
          paddingBottom={"0px"}
        >
          <Typography
            fontSize={34}
            color={"#178CE5"}
            fontWeight={700}
            fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
            marginTop={"18px"}
            lineHeight={"130%"}
          >
            SNS 마케팅, 이런 고민 해보셨나요?
          </Typography>

          <img
            alt="section2_info"
            src={`${process.env.PUBLIC_URL}/assets/images/pc/section2_info.png`}
            width={"100%"}
            height={"auto"}
            style={{ maxWidth: "1072px", marginTop: "59px" }}
          />
        </Stack>
      </Container>
    </Box>
  );
}

export default PcSection2;
