import { Button, Stack } from "@mui/material";
import React from "react";

function MobileSection6() {
  return (
    <Stack
      sx={{ backgroundColor: "#F9F9F9" }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={"40px"}
    >
      {/* <Typography
        fontSize={26}
        color={"#1E1E1E"}
        fontWeight={700}
        textAlign={"center"}
      >
        SNS 셀프마케팅을
        <br />
        <mark>무료로</mark> 경험해보세요
      </Typography> */}

      <img
        alt="section6_title"
        src={`${process.env.PUBLIC_URL}/assets/images/mobile/section6_title.png`}
        width={"223px"}
        height={"auto"}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
        }}
      />

      <Button
        variant="contained"
        sx={{
          // backgroundColor: "rgba(0, 87, 255, 0.80)",
          background: "rgba(0, 0, 0, 0.75)",
          backdropFilter: "blur(1px)",
          marginTop: "28px",
          marginLeft: "35px",
          marginRight: "35px",
          maxWidth: "320px",
          height: "54px",
          fontFamily: `"SUIT", "Helvetica", "Arial", sans-serif`,
          fontWeight: "700",
          fontSize: "18px",
          borderRadius: "10px",
          color: "white",
          textDecoration: "none",
          lineHeight: "120%",
        }}
        component="a"
        href="http://pf.kakao.com/_QxkrBG"
        target="_blank"
        fullWidth
      >
        쿠폰받고 마케팅 시작하기
        <img
          alt="section6_title"
          src={`${process.env.PUBLIC_URL}/assets/images/mobile/section6_arrow.png`}
          width={"12.46px"}
          height={"auto"}
          style={{ marginLeft: "12px" }}
        />
      </Button>
    </Stack>
  );
}

export default MobileSection6;
