import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";

function PcSection3() {
  return (
    <Box
      sx={{
        background: "#FFF",
      }}
    >
      <Container>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingTop={"100px"}
          paddingBottom={"100px"}
        >
          <Typography
            fontSize={50}
            color={"#1C7BD2"}
            fontWeight={700}
            fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
            lineHeight={"130%"}
          >
            SNS 마케팅 초기설정이 가장 중요해요!
          </Typography>

          <Typography
            fontSize={26}
            color={"#4D4D4D"}
            fontWeight={400}
            fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
            marginTop={"20px"}
            lineHeight={"130%"}
          >
            먼저, 게시물이 많은사람들에게 보여야 효과를 볼 수 있어요
          </Typography>

          <Typography
            fontSize={28}
            color={"#213A4E"}
            fontWeight={700}
            fontFamily={`"Noto Sans KR", "Helvetica", "Arial", sans-serif`}
            lineHeight={"120%"}
            marginTop={"60px"}
          >
            SNS 운영방법
          </Typography>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginTop={"34px"}
            sx={{
              fontFamily: `"Pretendard", "Helvetica", "Arial", sans-serif`,
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              py={"40px"}
              px={"20px"}
              backgroundColor="#ECF7FF"
              borderRadius={"20px"}
              sx={{
                backdropFilter: "blur(2px)",
                minWidth: "250px",
              }}
            >
              <Typography
                fontSize={24}
                color={"#1C7BD2"}
                fontWeight={500}
                textAlign={"center"}
                fontFamily="inherit"
                lineHeight={"120%"}
              >
                01 계정 활성화
              </Typography>

              <Box
                marginTop={"10px"}
                sx={{
                  color: "#303030",
                }}
              >
                <Typography
                  fontSize={18}
                  fontWeight={300}
                  textAlign={"center"}
                  lineHeight={"150%"}
                  fontFamily="inherit"
                >
                  SNS 계정을 생성하고
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    활동지수를 높여
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                    marginLeft={"4px"}
                  >
                    계정의
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    최적화 단계
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    에 이를 수
                  </Typography>
                </Stack>

                <Typography
                  fontSize={18}
                  fontWeight={300}
                  fontFamily="inherit"
                  lineHeight={"150%"}
                  textAlign={"center"}
                >
                  있도록 준비합니다.
                </Typography>
              </Box>
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0.5}
              mx={1.5}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#ECF7FF" />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#D4EDFF" />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#BAE2FF" />
              </svg>
            </Stack>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              py={"40px"}
              px={"20px"}
              backgroundColor="#ECF7FF"
              borderRadius={"20px"}
              sx={{
                backdropFilter: "blur(2px)",
                minWidth: "250px",
              }}
            >
              <Typography
                fontSize={24}
                color={"#1C7BD2"}
                fontWeight={500}
                textAlign={"center"}
                fontFamily="inherit"
                lineHeight={"120%"}
              >
                02 게시물 관리
              </Typography>

              <Box
                marginTop={"10px"}
                sx={{
                  color: "#303030",
                }}
              >
                <Typography
                  fontSize={18}
                  fontWeight={300}
                  textAlign={"center"}
                  lineHeight={"150%"}
                  fontFamily="inherit"
                >
                  컨설팅을 통해 SNS에
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                    marginRight={"4px"}
                  >
                    업로드한 사진의
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    콘텐츠
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    방향과 노출
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                    marginRight={"4px"}
                  >
                    을 위한
                  </Typography>

                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    전략적
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    컨설팅
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    을 진행합니다.
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0.5}
              mx={1.5}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#ECF7FF" />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#D4EDFF" />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#BAE2FF" />
              </svg>
            </Stack>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              py={"40px"}
              px={"20px"}
              backgroundColor="#ECF7FF"
              borderRadius={"20px"}
              sx={{
                backdropFilter: "blur(2px)",
                minWidth: "250px",
              }}
            >
              <Typography
                fontSize={24}
                color={"#1C7BD2"}
                fontWeight={500}
                textAlign={"center"}
                fontFamily="inherit"
                lineHeight={"120%"}
              >
                03 게시물 노출
              </Typography>

              <Box
                marginTop={"10px"}
                sx={{
                  color: "#303030",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                    marginRight={"4px"}
                  >
                    다양한 SNS채널에
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    고객들이
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    분포되어있는 곳을 분석
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    하여
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                    marginRight={"4px"}
                  >
                    게시물을 노출하여
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    나의
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    상품과 서비스를 노출
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    합니다.
                  </Typography>
                </Stack>
              </Box>
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0.5}
              mx={1.5}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#ECF7FF" />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#D4EDFF" />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="5"
                viewBox="0 0 6 5"
                fill="none"
              >
                <circle cx="3" cy="2.5" r="2.5" fill="#BAE2FF" />
              </svg>
            </Stack>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              py={"40px"}
              px={"20px"}
              backgroundColor="#ECF7FF"
              borderRadius={"20px"}
              sx={{
                backdropFilter: "blur(2px)",
                minWidth: "250px",
              }}
            >
              <Typography
                fontSize={24}
                color={"#1C7BD2"}
                fontWeight={500}
                textAlign={"center"}
                fontFamily="inherit"
                lineHeight={"120%"}
              >
                04 마케팅 효과
              </Typography>

              <Box
                marginTop={"10px"}
                sx={{
                  color: "#303030",
                }}
              >
                <Typography
                  fontSize={18}
                  fontWeight={300}
                  fontFamily="inherit"
                  lineHeight={"150%"}
                  marginRight={"4px"}
                  textAlign={"center"}
                >
                  업로드한 게시물의 콘텐츠를
                </Typography>

                <Typography
                  fontSize={18}
                  fontWeight={300}
                  fontFamily="inherit"
                  lineHeight={"150%"}
                  marginRight={"4px"}
                  textAlign={"center"}
                >
                  통하여 고객에게 전달된
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    데이터와 문의를 기반
                  </Typography>
                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    으로
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                  >
                    마케팅 효과들을 파악
                  </Typography>

                  <Typography
                    fontSize={18}
                    fontWeight={300}
                    fontFamily="inherit"
                    lineHeight={"150%"}
                    component={"span"}
                    marginRight={"4px"}
                  >
                    합니다.
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default PcSection3;
