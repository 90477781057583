import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0062F5",
    },
    secondary: {
      main: "#38A5F9",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    // fontFamily: `"GmarketSans", "Helvetica", "Arial", sans-serif`,
    fontFamily: `"Noto Sans KR", "Helvetica", "Arial", sans-serif`,
  },
});

export default theme;
