import { Stack, Typography } from "@mui/material";
import React from "react";

function MobileSection4() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={"50px"}
    >
      <Typography
        fontSize={26}
        color={"#1C7BD2"}
        fontWeight={700}
        textAlign={"center"}
        lineHeight={"130%"}
      >
        SNS 마케팅
        <br />
        초기설정이 가장 중요해요!
      </Typography>

      <Typography
        fontSize={20}
        color={"#134E7C"}
        fontWeight={400}
        textAlign={"center"}
        fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
        marginTop={"21px"}
        lineHeight={"130%"}
      >
        먼저, 게시물이 많은사람들에게
        <br />
        보여야 효과를 볼 수 있어요
      </Typography>

      <Typography
        fontSize={18}
        color={"#213A4E"}
        fontWeight={700}
        textAlign={"center"}
        marginTop={"21px"}
        lineHeight={"120%"}
      >
        SNS 운영방법
      </Typography>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        py={"25px"}
        px={"30px"}
        backgroundColor="rgba(160, 214, 255, 0.20)"
        borderRadius={"20px"}
        sx={{ backdropFilter: "blur(2px)" }}
        marginTop={"10px"}
      >
        <Typography
          fontSize={16}
          color={"#1C7BD2"}
          fontWeight={600}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          lineHeight={"120%"}
        >
          01 계정 활성화
        </Typography>

        <Typography
          fontSize={15}
          color={"#303030"}
          fontWeight={400}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"150%"}
        >
          SNS 계정을 생성하고 활동지수를 높여
          <br />
          계정의 최적화 단계에 이를 수 있도록
          <br />
          준비합니다.
        </Typography>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        my={1}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#ECF7FF" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#D4EDFF" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#BAE2FF" />
        </svg>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        py={"25px"}
        px={"30px"}
        backgroundColor="rgba(160, 214, 255, 0.20)"
        borderRadius={"20px"}
        sx={{ backdropFilter: "blur(2px)" }}
      >
        <Typography
          fontSize={16}
          color={"#1C7BD2"}
          fontWeight={600}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          lineHeight={"120%"}
        >
          02 게시물 관리
        </Typography>

        <Typography
          fontSize={15}
          color={"#303030"}
          fontWeight={400}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"150%"}
        >
          컨설팅을 통해 SNS에 업로드한 사진의
          <br />
          콘텐츠의 방향과 노출을 위한 전략적
          <br />
          컨설팅을 진행합니다.
        </Typography>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        my={1}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#ECF7FF" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#D4EDFF" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#BAE2FF" />
        </svg>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        py={"25px"}
        px={"30px"}
        backgroundColor="rgba(160, 214, 255, 0.20)"
        borderRadius={"20px"}
        sx={{ backdropFilter: "blur(2px)" }}
      >
        <Typography
          fontSize={16}
          color={"#1C7BD2"}
          fontWeight={600}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          lineHeight={"120%"}
        >
          03 게시물 노출
        </Typography>

        <Typography
          fontSize={15}
          color={"#303030"}
          fontWeight={400}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"150%"}
        >
          다양한 SNS채널에 고객들이 분포되어
          <br />
          있는 곳을 분석하여 게시물을 노출하여
          <br />
          나의 상품과 서비스를 노출합니다.
        </Typography>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        my={1}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#ECF7FF" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#D4EDFF" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="5"
          viewBox="0 0 6 5"
          fill="none"
        >
          <circle cx="3" cy="2.5" r="2.5" fill="#BAE2FF" />
        </svg>
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        py={"25px"}
        px={"30px"}
        backgroundColor="rgba(160, 214, 255, 0.20)"
        borderRadius={"20px"}
        sx={{ backdropFilter: "blur(2px)" }}
      >
        <Typography
          fontSize={16}
          color={"#1C7BD2"}
          fontWeight={600}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          lineHeight={"120%"}
        >
          04 마케팅효과
        </Typography>

        <Typography
          fontSize={15}
          color={"#303030"}
          fontWeight={400}
          textAlign={"center"}
          fontFamily={`"Pretendard", "Helvetica", "Arial", sans-serif`}
          marginTop={"10px"}
          lineHeight={"150%"}
        >
          업로드한 게시물의 콘텐츠를 통하여
          <br />
          고객에게 전달된 데이터와 문의를
          <br />
          기반으로 마케팅 효과들을 파악합니다.
        </Typography>
      </Stack>
    </Stack>
  );
}

export default MobileSection4;
